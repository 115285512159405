@use "../constants" as *;
@use "Mobile";
@use "AlbumPreview";

.Photo {
  padding: 0 $gutter-width;
  padding-top: $header-height;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  transition:
    height 250ms,
    grid-template-rows 250ms;

  @media (max-width: $mobile) {
    display: none;

    &__mobile {
      display: flex;
    }
  }

  &-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.PhotoPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $gutter-width;
  padding-top: $header-height;

  &__title {
    font-size: 4rem;
    padding: calc($gutter-width * 3);
  }
}
