@use "constants" as *;

.Menu {
  @include unselectable;

  width: 100%;
  display: flex;

  position: fixed;
  z-index: 999;
  top: 0;

  flex-direction: column;
  justify-content: flex-end;
  transition:
    color 150ms ease-out,
    background 150ms ease-out;

  scroll-snap-align: start;
  color: $bg-light-main;

  .dark & {
    background: $bg-dark-accent;
  }

  .light & {
    background: $bg-light-accent;
  }


  &-pages {
    width: 100%;
    padding: $gutter-width;
    padding-bottom: calc($gutter-width * 3);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: $gutter-width;

    &__link {
      transition: color 150ms ease-out;
      @include strikethrough-button;

      height: 64px;
      display: inline-block; // makes strikethrough align in the middle of link

      &::after {
        height: 5px;
      }

      color: $bg-light-main;
      text-decoration: none;
      font-size: 63px;
      line-height: 53px;
      font-family: $font-accent;
      font-variant: small-caps;

      &::after {
        background: $bg-light-main !important;
      }
    }
  }

  &-bottom {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    padding: 0 $gutter-width;
    padding-bottom: $gutter-width;
  }

  &-socials, &-controls {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__link {
      @include strikethrough-button;
      margin: 0;
      color: $bg-light-main;
      text-decoration: none;
      font-size: 20px;
      font-variant: small-caps;
      font-weight: bold;

      &::after {
        background: $bg-light-main !important;
      }
    }
  }

  &-socials {
    align-items: flex-start;
  }

  &-controls {
    align-items: flex-end;
  }
}
