.About {
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  min-height: 80vh;
  padding-top: 70px;
  display: flex;
}

.About__container {
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 100%;
  max-width: 1000px;
  height: min-content;
  margin: 0 auto;
  padding: 15px;
  display: grid;
}

.About__image {
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: calc(100vh - 100px);
  margin-left: auto;
  transition: background .3s;
  display: flex;
}

.About__image span {
  font-size: 13px;
  font-weight: 600;
  transition: color .3s;
}

.About__text {
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  width: 100%;
  max-width: 600px;
  display: flex;
}

@media (width <= 700px) {
  .About {
    padding-top: 55px;
  }
}

@media (width <= 768px) {
  .About {
    height: unset;
  }

  .About__container {
    grid-template-columns: 1fr;
  }

  .About__image {
    height: unset;
    width: 100%;
  }

  .About__text {
    width: 100%;
    padding-top: 0;
  }
}

.light .About__image {
  background: #fff;
}

.dark .About__image {
  background: #000;
}

.Bio {
  text-align: justify;
  flex-direction: column;
  gap: 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  display: flex;
}

.Bio--link {
  color: inherit;
  text-decoration: underline;
  transition: color .3s;
}

.Bio--link :hover {
  transition: color .15s;
}

.Bio__name {
  transition: color .3s;
}

.Bio__text {
  transition: color .3s;
  display: inline;
}

.Socials {
  flex-direction: column;
  gap: 30px;
  width: min-content;
  display: flex;
}

.Socials__topline {
  width: 100%;
  height: 3px;
  transition: background .3s;
}

.Socials__links {
  gap: 16px;
  width: min-content;
  display: flex;
}

.Socials--link {
  color: inherit;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  transition: color .3s;
  display: flex;
}

.Socials--link :hover {
  transition: color .15s;
}

.dark .Socials__topline {
  background: #f8f8f8;
}

.light .Socials__topline {
  background: #262626;
}

.App {
  min-height: 100vh;
  transition: background .3s;
}

.dark {
  color: #f8f8f8;
  background: #121212;
}

.light {
  color: #262626;
  background: #f8f8f8;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: Space Mono, monospace;
  transition: color .3s;
}

p {
  margin: 0;
  transition: color .3s;
}

.Arc {
  min-height: 100vh;
  padding: 70px 15px 15px;
}

@media (width <= 700px) {
  .Arc {
    padding-top: 55px;
  }
}

.Arc__link {
  color: inherit;
  font-family: Space Mono, monospace;
  font-weight: bold;
  text-decoration: none;
  transition: color .3s;
}

.Arc__link :hover {
  transition: color .15s;
}

.Arc__header {
  margin-bottom: 15px;
  transition: background .3s;
}

.Arc-album {
  flex-flow: wrap;
  align-items: center;
  gap: 15px;
  width: 100%;
  display: flex;
}

.Arc-album__container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.Arc-table {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
}

.Arc-table__title {
  padding: 15px 0;
}

.Arc-table__year {
  grid-gap: 15px;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: 15px;
  display: grid;
}

.Arc-table__year__album {
  background-position: center;
  background-size: cover;
  grid-column: span 2;
  width: 100%;
  height: calc(min(100vw - 105px, 1125px) / 6);
  text-decoration: none;
}

@media (width <= 900px) {
  .Arc-table__year__album {
    grid-column: span 3;
    height: calc(25vw - 18.75px);
  }
}

@media (width <= 700px) {
  .Arc-table__year__album {
    grid-column: span 4;
    height: calc(33.33vw - 20px);
  }
}

@media (width <= 530px) {
  .Arc-table__year__album {
    grid-column: span 6;
    height: calc(50vw - 22.5px);
  }
}

@media (width <= 370px) {
  .Arc-table__year__album {
    grid-column: span 12;
    height: calc(100vw - 30px);
  }
}

.Arc-table__year__overlay {
  color: #f8f8f8;
  font-variant: small-caps;
  opacity: 0;
  background-color: #000000b3;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 100%;
  padding: 15px 10px 10px;
  font-size: 12px;
  font-weight: bold;
  transition: opacity .3s;
  display: flex;
}

.Arc-table__year__overlay:hover {
  opacity: 1;
}

.Arc-table__year__overlay > p:last-child {
  font-weight: normal;
}

.Header {
  z-index: 1000;
  grid-gap: 15px;
  -webkit-user-select: none;
  user-select: none;
  font-variant: small-caps;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  height: 70px;
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
  display: grid;
  position: fixed;
  top: 0;
}

.Header__background {
  z-index: 10;
  background: #f8f8f8fa;
  width: 100%;
  height: 70px;
  transition: background .3s;
  position: fixed;
  top: 0;
}

.Header--btn, .Header--link {
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  font-variant: inherit;
  color: inherit;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  text-decoration: none;
  transition: color .3s;
  display: flex;
  position: relative;
}

.Header--btn:after, .Header--link:after {
  content: " ";
  z-index: 1;
  width: 0%;
  height: 2px;
  transition: width .2s, background .3s;
  position: absolute;
  top: 51%;
  right: -5px;
}

.dark .Header--btn:after, .dark .Header--link:after {
  background: #f8f8f8;
}

.light .Header--btn:after, .light .Header--link:after {
  background: #262626;
}

.Header--btn:hover:after, .Header--btn.selected:after, .Header--link:hover:after, .Header--link.selected:after {
  width: calc(100% + 10px);
  left: -5px;
}

.Header--btn:disabled, .Header--link:disabled {
  cursor: default;
}

.Header--btn:disabled:after, .Header--link:disabled:after {
  display: none;
}

.Header__name {
  opacity: 1;
  justify-self: left;
  transition: opacity .3s;
}

.Header__bio {
  flex-direction: column;
  grid-column: 2 / span 2;
  align-items: flex-start;
  display: flex;
}

.Header__nav {
  grid-gap: 0 15px;
  grid-column: 5 / span 1;
  grid-template-columns: 1fr 1fr;
  justify-self: end;
  place-items: start end;
  width: 100%;
  display: grid;
}

.Header__mode {
  justify-self: right;
}

.Header__menu {
  display: none;
}

.light .Header {
  color: #262626;
}

.light .Header__background {
  background: #f8f8f8fa;
}

.light .Header :after {
  background: #262626;
}

.light .Header--open {
  color: #f8f8f8;
}

.light .Header--open :after {
  background: #f8f8f8;
}

.dark .Header {
  color: #f8f8f8;
}

.dark .Header__background {
  background: #121212fa;
}

.dark .Header :after {
  background: #f8f8f8;
}

.dark .Header--open {
  color: #f8f8f8;
}

.dark .Header--open :after {
  background: #f8f8f8;
}

@media (width <= 700px) {
  .Header {
    grid-template-columns: 1fr 1fr;
    height: 55px;
    font-size: 18px;
  }

  .Header__mode, .Header__nav, .Header__bio {
    display: none;
  }

  .Header__name.hidden {
    opacity: 0;
    transition-delay: 0s;
  }

  .Header__name :after {
    display: none;
  }

  .Header__menu {
    justify-self: right;
    display: flex;
  }

  .Header__menu:not(.selected):after {
    width: 0;
  }

  .Header__background {
    height: 55px;
    transition: opacity .3s;
  }
}

.Menu {
  -webkit-user-select: none;
  user-select: none;
  z-index: 999;
  scroll-snap-align: start;
  color: #f8f8f8;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  transition: color .15s ease-out, background .15s ease-out;
  display: flex;
  position: fixed;
  top: 0;
}

.dark .Menu {
  background: #0865c0;
}

.light .Menu {
  background: #0d7ae5;
}

.Menu-pages {
  flex-flow: column wrap;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  padding: 15px 15px 45px;
  display: flex;
}

.Menu-pages__link {
  cursor: pointer;
  color: #f8f8f8;
  font-variant: small-caps;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 0;
  font-family: Space Mono, monospace;
  font-size: 63px;
  line-height: 53px;
  text-decoration: none;
  transition: color .15s ease-out;
  display: inline-block;
  position: relative;
}

.Menu-pages__link:after {
  content: " ";
  z-index: 1;
  width: 0%;
  height: 2px;
  transition: width .2s, background .3s;
  position: absolute;
  top: 51%;
  right: -5px;
}

.dark .Menu-pages__link:after {
  background: #f8f8f8;
}

.light .Menu-pages__link:after {
  background: #262626;
}

.Menu-pages__link:hover:after, .Menu-pages__link.selected:after {
  width: calc(100% + 10px);
  left: -5px;
}

.Menu-pages__link:disabled {
  cursor: default;
}

.Menu-pages__link:disabled:after {
  display: none;
}

.Menu-pages__link:after {
  height: 5px;
  background: #f8f8f8 !important;
}

.Menu-bottom {
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 0 15px 15px;
  display: grid;
}

.Menu-socials, .Menu-controls {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.Menu-socials__link, .Menu-controls__link {
  cursor: pointer;
  color: #f8f8f8;
  font-variant: small-caps;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  position: relative;
}

.Menu-socials__link:after, .Menu-controls__link:after {
  content: " ";
  z-index: 1;
  width: 0%;
  height: 2px;
  transition: width .2s, background .3s;
  position: absolute;
  top: 51%;
  right: -5px;
}

.dark .Menu-socials__link:after, .dark .Menu-controls__link:after {
  background: #f8f8f8;
}

.light .Menu-socials__link:after, .light .Menu-controls__link:after {
  background: #262626;
}

.Menu-socials__link:hover:after, .Menu-socials__link.selected:after, .Menu-controls__link:hover:after, .Menu-controls__link.selected:after {
  width: calc(100% + 10px);
  left: -5px;
}

.Menu-socials__link:disabled, .Menu-controls__link:disabled {
  cursor: default;
}

.Menu-socials__link:disabled:after, .Menu-controls__link:disabled:after {
  display: none;
}

.Menu-socials__link:after, .Menu-controls__link:after {
  background: #f8f8f8 !important;
}

.Menu-socials {
  align-items: flex-start;
}

.Menu-controls {
  align-items: flex-end;
}

.Work {
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 70px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.Work__grid {
  min-height: 100%;
}

.Work__description {
  text-align: center;
  grid-template-columns: repeat(12, 1fr);
  gap: 15px;
  width: 100%;
  max-width: 1230px;
  margin: 0 auto 60px;
  padding: 0 15px;
  font-size: 16px;
  display: grid;
}

.Work__description_title {
  grid-column: 3 / -1;
}

@media (width <= 700px) {
  .Work__description_title {
    grid-column: 1 / -1;
  }
}

.Work__description_text {
  text-align: justify;
  grid-column: 4 / span 6;
  font-size: 1rem;
  line-height: 1.75;
}

@media (width <= 1000px) {
  .Work__description_text {
    grid-column: 3 / span 8;
  }
}

@media (width <= 700px) {
  .Work__description_text {
    grid-column: 1 / -1;
  }
}

.Work__description_links {
  font-variant: small-caps;
  flex-direction: column;
  grid-column: 4 / span 6;
  gap: 10px;
  width: fit-content;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.75;
  display: flex;
}

@media (width <= 1000px) {
  .Work__description_links {
    grid-column: 3 / span 8;
  }
}

@media (width <= 700px) {
  .Work__description_links {
    grid-column: 1 / -1;
  }
}

.dark .Work__description_links {
  color: #f8f8f8 !important;
}

.light .Work__description_links {
  color: #262626 !important;
}

.Work__title {
  text-align: center;
  grid-template-columns: min-content 1fr min-content;
  max-width: 1230px;
  margin: 0 auto;
  padding: 30px 15px 45px;
  font-family: Space Mono, monospace;
  transition: all .2s ease-in-out;
  display: grid;
}

.Work__title h1 {
  grid-column: 2;
  padding: 0 30px;
  font-size: 3rem;
}

.WorkPreview {
  background-position: top;
  background-size: cover;
  flex-direction: column;
  grid-template-columns: repeat(12, 1fr);
  gap: 60px 15px;
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 45px 15px 15px;
  display: grid;
  position: relative;
}

.WorkPreview__card {
  z-index: 2;
  color: inherit;
  grid-column: 2 / 12;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
  text-decoration: none;
  display: grid;
  position: relative;
}

@media (width <= 1000px) {
  .WorkPreview__card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 700px) {
  .WorkPreview__card {
    grid-column: 1 / -1;
    grid-template-columns: 1fr;
  }
}

.WorkPreview__card_image.hovered {
  filter: blur(.25px);
}

.WorkPreview__card_hover {
  z-index: 9;
  opacity: 0;
  grid-column: 1 / -1;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
  height: calc(100% - 43px);
  transition: opacity .2s ease-in-out;
  display: grid;
  position: absolute;
  bottom: 0;
}

@media (width <= 1000px) {
  .WorkPreview__card_hover {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 700px) {
  .WorkPreview__card_hover {
    grid-column: 1 / -1;
    grid-template-columns: 1fr;
  }
}

.WorkPreview__card_hover.hovered {
  opacity: 1;
}

.WorkPreview__card_hover_label {
  opacity: 1;
  z-index: 11;
  color: #f8f8f8;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
}

.WorkPreview__card_hover_overlay {
  z-index: 10;
  opacity: .4;
  background-color: #121212;
  width: 100%;
}

.WorkPreview__card_text {
  flex-direction: column;
  grid-column: 1 / -1;
  justify-content: center;
  width: 100%;
  height: 28px;
  display: flex;
}

.WorkPreview__card_text_title {
  font-variant: small-caps;
  height: 28px;
  font-weight: bold;
}

.WorkPreview__card_text_description {
  grid-column: 2 / 5;
}

.WorkPreview__card_text p {
  font-variant: small-caps;
  font-size: 16px;
  font-weight: bold;
}

@keyframes aimation-loading {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.Image {
  height: inherit;
  width: inherit;
  grid-template: 1fr / 1fr;
  place-items: center;
  display: grid;
}

.Image__loading {
  z-index: 1;
  grid-area: 1 / 1 / 1 / 1;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.Image__loading:after {
  content: "";
  animation: 2s linear .5s infinite aimation-loading;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.dark .Image__loading {
  background-color: #000;
}

.dark .Image__loading:after {
  background: linear-gradient(90deg, #0000, #ffffff0f, #0000);
}

.light .Image__loading {
  background-color: #fff;
}

.light .Image__loading:after {
  background: linear-gradient(90deg, #0000, #0000000a, #0000);
}

.Image__img {
  z-index: 2;
  grid-area: 1 / 1 / 1 / 1;
  transition: opacity .3s 40ms;
}

.Image__img.hide {
  opacity: 0;
}

.riser {
  width: 100%;
  overflow: hidden;
}

.PhotoGrid {
  scrollbar-width: none;
  -ms-overflow-style: none;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-self: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
}

.PhotoGrid::-webkit-scrollbar {
  display: none;
}

.PhotoGrid::-webkit-scrollbar-button {
  display: none;
}

.PhotoGrid__column {
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  display: flex;
}

.PhotoGrid__column::-webkit-scrollbar {
  display: none;
}

.PhotoGrid__column::-webkit-scrollbar-button {
  display: none;
}

.PhotoGrid__photo {
  cursor: pointer;
  width: 100%;
  display: flex;
  position: relative;
}

.PhotoGrid__photo img {
  height: inherit;
}

.PhotoGrid-overlay {
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.PhotoGrid-overlay__photo {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
}

.PhotoGrid-overlay__photo.portrait .PhotoGrid-overlay__image {
  flex-direction: column;
}

.PhotoGrid-overlay__photo.portrait .PhotoGrid-overlay__info {
  height: 60px;
  padding: 0 15px;
}

.PhotoGrid-overlay__photo.landscape .PhotoGrid-overlay__info {
  writing-mode: vertical-rl;
  width: 60px;
  padding: 15px 0;
}

.PhotoGrid-overlay__image {
  cursor: default;
  width: min-content;
  height: min-content;
  display: flex;
}

.PhotoGrid-overlay__image img {
  height: inherit;
}

.PhotoGrid-overlay__info {
  background: #fff;
  grid-template-columns: 1fr 1fr 1fr;
  font-family: Space Mono, monospace;
  display: grid;
}

.PhotoGrid-overlay__info h4:first-child {
  place-self: center start;
}

.PhotoGrid-overlay__info h4:last-child {
  place-self: center end;
}

.PhotoGrid-overlay__info_count {
  place-self: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  display: flex;
}

.portrait .PhotoGrid-overlay__info_count {
  gap: 7.5px;
  height: 100%;
}

.landscape .PhotoGrid-overlay__info_count {
  writing-mode: horizontal-tb;
  flex-direction: column;
  gap: 3.75px;
  width: 100%;
}

.PhotoGrid-overlay__controls {
  cursor: pointer;
  z-index: 1001;
  font-variant: small-caps;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  position: absolute;
}

.PhotoGrid-overlay__controls:disabled {
  color: gray !important;
}

.PhotoGrid-overlay__controls.close {
  top: 0;
  right: 0;
}

.PhotoGrid-overlay__controls.prev {
  top: calc(50% - 31px);
  left: 0;
}

.PhotoGrid-overlay__controls.next {
  top: calc(50% - 31px);
  right: 0;
}

.dark .PhotoGrid-overlay {
  background: #121212fa;
}

.dark .PhotoGrid-overlay__info {
  background: #000;
}

.light .PhotoGrid-overlay {
  background: #f8f8f8fa;
}

.light .PhotoGrid-overlay__info {
  background: #fff;
}

.ScrollTop {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 15px 45px;
  display: flex;
}

.ScrollTop__button {
  cursor: pointer;
  font-variant: small-caps;
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

.light .ScrollTop__button {
  color: #262626;
}

.dark .ScrollTop__button {
  color: #f8f8f8;
}

.HoverButton {
  cursor: pointer;
  font-variant: small-caps;
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

.light .HoverButton {
  color: #262626;
}

.dark .HoverButton {
  color: #f8f8f8;
}

.HoverButton:disabled {
  color: gray !important;
}

.Title {
  text-align: center;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding-top: 45px;
  padding-bottom: 45px;
  font-family: Space Mono, monospace;
  transition: all .2s ease-in-out;
  display: grid;
}

@media (width <= 700px) {
  .Title {
    grid-template-columns: min-content 1fr;
    padding: 15px 0;
  }
}

.Title h2 {
  justify-self: center;
  width: 500px;
}

@media (width <= 700px) {
  .Title h2 {
    text-align: right;
    width: 100%;
    font-size: 1rem;
  }
}

.Title__buttons {
  align-self: center;
  display: flex;
}

.Title__buttons.navigation {
  justify-content: flex-end;
  gap: 15px;
}

@media (width <= 700px) {
  .Title__buttons.navigation {
    display: none;
  }
}

.Title__button {
  padding: 0;
}

.Grid {
  z-index: 9999;
  width: 100dvw;
  height: 100dvh;
  padding: 0 15px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.Grid__container {
  grid-gap: 15px;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  margin: 0 auto;
  display: grid;
}

.Grid__column {
  background-color: #ff00001a;
  width: 100%;
  height: 100%;
}

.Grid__toggle {
  color: #fff;
  cursor: pointer;
  z-index: 100000;
  background-color: red;
  width: 40px;
  height: 40px;
  margin: 10px;
  padding: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
}

.IconButton {
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 3px;
  display: flex;
  position: relative;
}

.IconButton__strike {
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  transform: rotate(-45deg);
}

.IconButton__strike--outer {
  width: 0%;
  height: 6px;
  padding: 2px 0;
  transition: width .3s;
  position: absolute;
  top: calc(50% - 3px);
  right: -5px;
}

.IconButton__strike--inner {
  width: 100%;
  height: 100%;
}

.dark .IconButton__strike--inner {
  background: #f8f8f8;
}

.light .IconButton__strike--inner {
  background: #262626;
}

.dark .IconButton__strike--outer {
  background: #121212;
}

.light .IconButton__strike--outer {
  background: #f8f8f8;
}

.IconButton:hover .IconButton__strike--outer, .IconButton.hovered .IconButton__strike--outer {
  width: calc(100% + 10px);
  left: -5px;
}

.LinkButton {
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  display: flex;
}

.dark .LinkButton {
  color: #f8f8f8;
}

.light .LinkButton {
  color: #262626;
}

.Photo-mobile {
  width: 100%;
  padding-top: 55px;
}

.Photo-mobile__bio {
  font-variant: small-caps;
  padding: 0 15px;
  font-size: 18px;
  font-weight: bold;
}

.Photo-mobile__header {
  font-variant: none;
  font-size: 2.5rem;
}

.Photo-mobile__topline {
  width: 80px;
  height: 3px;
  margin: 30px 0 105px 15px;
  display: flex;
}

.dark .Photo-mobile__topline {
  background-color: #f8f8f8;
}

.light .Photo-mobile__topline {
  background-color: #262626;
}

.Photo-mobile .Album-mobile {
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  display: flex;
}

.Photo-mobile .Album-mobile__title {
  width: 100%;
  padding: 0 15px 15px;
}

.Photo-mobile .Album-mobile__count {
  padding-top: 15px;
  font-family: Space Mono, monospace;
  font-size: .75rem;
  font-weight: bold;
}

.Photo-mobile .Album-photos {
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
  align-items: center;
  width: 100%;
  display: flex;
  overflow-x: scroll;
}

.Photo-mobile .Album-photos::-webkit-scrollbar {
  display: none;
}

.Photo-mobile .Album-photos::-webkit-scrollbar-button {
  display: none;
}

.Photo-mobile .Album-photos__container {
  scroll-snap-align: start;
  width: 100%;
}

.Photo-mobile .Album-photos__image {
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 0 15px;
  display: flex;
}

.AlbumPreview {
  scrollbar-width: none;
  -ms-overflow-style: none;
  grid-template-columns: 1fr 1fr 1fr;
  justify-self: center;
  gap: 15px;
  width: 100%;
  max-width: 1200px;
  padding-top: 0;
  display: grid;
  overflow: scroll;
}

.AlbumPreview::-webkit-scrollbar {
  display: none;
}

.AlbumPreview::-webkit-scrollbar-button {
  display: none;
}

.AlbumPreview__link {
  width: 100%;
}

.AlbumPreview__trailer {
  height: 100%;
  padding-left: 200px;
}

.AlbumPreview__image {
  height: 100%;
}

.AlbumPreview__indicator {
  width: 100%;
  height: 3px;
  margin-top: 3px;
  display: block;
}

.dark .AlbumPreview__indicator {
  background: #f8f8f8;
}

.light .AlbumPreview__indicator {
  background: #262626;
}

.Photo {
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 70px 15px 0;
  transition: height .25s, grid-template-rows .25s;
  display: flex;
}

@media (width <= 700px) {
  .Photo {
    display: none;
  }

  .Photo__mobile {
    display: flex;
  }
}

.Photo-container {
  justify-content: center;
  width: 100%;
  display: flex;
}

.PhotoPreview {
  flex-direction: column;
  align-items: center;
  padding: 70px 15px 0;
  display: flex;
}

.PhotoPreview__title {
  padding: 45px;
  font-size: 4rem;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8;
  margin: 0;
  font-family: Inter, sans-serif;
}

* {
  box-sizing: border-box;
}
/*# sourceMappingURL=index.d8d3e602.css.map */
