@use "../constants" as *;

.PhotoGrid {
  @include sans-scrollbar;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: $gutter-width;
  width: 100%;
  max-width: 1200px;
  justify-self: center;
  margin: 0 auto;

  &__column {
    @include sans-scrollbar;
    display: flex;
    flex-direction: column;
    gap: $gutter-width;
    width: 100%;
  }

  &__photo {
    width: 100%;

    display: flex;
    position: relative;
    cursor: pointer;

    img {
      height: inherit;
    }
  }
}

.PhotoGrid-overlay {
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;

  &__photo {
    cursor: pointer;
    width: 100vw;
    height: 100vh;

    position: absolute;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    &.portrait {
      .PhotoGrid-overlay__image {
        flex-direction: column;
      }

      .PhotoGrid-overlay__info {
        padding: 0 $gutter-width;
        height: $gutter-width * 4;
      }
    }

    &.landscape {
      .PhotoGrid-overlay__info {
        padding: $gutter-width 0;
        width: $gutter-width * 4;
        writing-mode: vertical-rl;
      }
    }
  }

  &__image {
    display: flex;
    width: min-content;
    height: min-content;
    cursor: default;

    img {
      height: inherit;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-family: $font-accent;
    background: #fff;

    h4:first-child {
      align-self: center;
      justify-self: start;
    }

    h4:last-child {
      align-self: center;
      justify-self: end;
    }

    &_count {
      align-self: center;
      justify-self: center;

      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;

      .portrait & {
        height: 100%;
        gap: calc($gutter-width / 2);
      }

      .landscape & {
        width: 100%;
        writing-mode: horizontal-tb;
        flex-direction: column;
        gap: calc($gutter-width / 4);
      }
    }
  }

  &__controls {
    @include plain-button;

    position: absolute;
    z-index: 1001;

    font-weight: bold;
    font-variant: small-caps;

    &:disabled {
      color: grey !important;
    }

    &.close {
      top: 0;
      right: 0;
    }

    &.prev {
      left: 0;
      top: calc(50% - $gutter-width - 16px);
    }

    &.next {
      right: 0;
      top: calc(50% - $gutter-width - 16px);
    }
  }

  .dark & {
    background: rgba($bg-dark-main, 0.98);

    &__info {
      background: #000;
    }
  }

  .light & {
    background: rgba($bg-light-main, 0.98);

    &__info {
      background: #fff;
    }
  }
}
