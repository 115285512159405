@use "constants" as *;

.App {
  transition: background 300ms;
  min-height: 100vh;
}

.dark {
  background: $bg-dark-main;
  color: $fg-dark-main;
}

.light {
  background: $bg-light-main;
  color: $fg-light-main;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $font-accent;
  transition: color 300ms;
}

p {
  margin: 0;
  transition: color 300ms;
}
