@use "constants" as *;

.Arc {
  padding: $gutter-width;
  padding-top: $header-height;
  min-height: 100vh;

  @media (max-width: $mobile) {
    padding-top: $header-height-mobile;
  }

  &__link {
    @include common-link;
    font-family: $font-accent;
    font-weight: bold;
  }

  &__header {
    margin-bottom: $gutter-width;
    transition: background 300ms;
  }
}

.Arc-album {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: $gutter-width;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.Arc-table {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: calc($gutter-width * 2);

  &__title {
    padding: $gutter-width 0;
  }

  &__year {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: $gutter-width;
    padding-bottom: $gutter-width;

    &__album {
      height: calc(
        (min(100vw - 7 * $gutter-width, 1200px - 5 * $gutter-width)) / 6
      );
      width: 100%;
      grid-column: span 2;

      text-decoration: none;
      background-position: center;
      background-size: cover;

      @media (max-width: 900px) {
        height: calc(25vw - $gutter-width * 5 / 4);
        grid-column: span 3;
      }

      @media (max-width: $mobile) {
        height: calc(33.33vw - $gutter-width * 4 / 3);
        grid-column: span 4;
      }

      @media (max-width: 530px) {
        height: calc(50vw - $gutter-width * 1.5);
        grid-column: span 6;
      }

      @media (max-width: 370px) {
        height: calc(100vw - $gutter-width * 2);
        grid-column: span 12;
      }
    }

    &__overlay {
      padding: 10px;
      padding-top: 15px;
      height: 100%;
      width: 100%;

      color: $fg-dark-main;
      font-size: 12px;
      font-weight: bold;
      font-variant: small-caps;

      display: flex;
      flex-direction: column;
      gap: 5px;

      // overlay transition styles
      background-color: rgba(0, 0, 0, 0.7);
      transition: opacity 300ms;
      opacity: 0;
      &:hover {
        opacity: 1;
      }

      > p:last-child {
        font-weight: normal;
      }
    }
  }
}
