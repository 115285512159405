@use "../constants" as *;

.Grid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  padding: 0 $gutter-width;
  z-index: 9999;
  display: flex;

  &__container {
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-gap: $gutter-width;
    grid-template-columns: repeat(12, 1fr);
  }

  &__column {
    background-color: rgba(255, 0, 0, 0.1);
    height: 100%;
    width: 100%;
  }

  &__toggle {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px;
    margin: 10px;
    height: 40px;
    width: 40px;
    color: white;
    background-color: red;
    cursor: pointer;
    z-index: 100000;
  }
}
