@use "../constants" as *;

.Photo-mobile {
  width: 100%;
  padding-top: $header-height-mobile;

  &__bio {
    padding: $gutter-width;
    padding-top: 0;
    padding-bottom: 0;

    font-weight: bold;
    font-size: 18px;
    font-variant: small-caps;
  }

  &__header {
    font-variant: none;
    font-size: 2.5rem;
  }

  &__topline {
    height: 3px;
    width: 80px;
    margin: calc($gutter-width * 2) 0;
    margin-top: 30px;
    margin-left: $gutter-width;
    margin-bottom: calc($gutter-width * 7);

    display: flex;

    .dark & {
      background-color: $fg-dark-main;
    }

    .light & {
      background-color: $fg-light-main;
    }
  }

  .Album-mobile {
    padding-bottom: calc($gutter-width * 2);

    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      width: 100%;
      padding: 0 $gutter-width;
      padding-bottom: $gutter-width;
    }

    &__count {
      padding-top: $gutter-width;

      font-family: $font-accent;
      font-weight: bold;
      font-size: 0.75rem;
    }
  }

  .Album-photos {
    @include sans-scrollbar;
    width: 100%;

    display: flex;
    align-items: center;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    &__container {
      width: 100%;
      scroll-snap-align: start;
    }

    &__image {
      padding: 0 $gutter-width;
      width: 100vw;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
