$first-transition-time: 100ms;
$first-gap-time: 100ms;
$first-delay: calc($first-transition-time + $first-gap-time);
$second-transition-time: 100ms;

$bg-light-main: #f8f8f8;
$bg-light-overlay: #fff;
$fg-light-main: #262626;
$bg-light-accent: #0d7ae5;

$bg-dark-main: #121212;
$bg-dark-overlay: #000;
$fg-dark-main: #f8f8f8;
$bg-dark-accent: #0865c0;

$font-main: "Inter", sans-serif;
$font-accent: "Space Mono", monospace;


@mixin plain-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

@mixin strikethrough-button {
  @include plain-button;
  position: relative;

  &::after {
    position: absolute;
    top: 51%;
    right: -5px;
    content: " ";
    height: 2px;
    width: 0%;
    transition:
      width 200ms,
      background 300ms;

    z-index: 1;
  }

  .dark &::after {
    background: $fg-dark-main;
  }

  .light &::after {
    background: $fg-light-main;
  }

  &:hover::after,
  &.selected::after {
    left: -5px;
    width: calc(100% + 10px);
  }

  &:disabled {
    cursor: default;
    &::after {
      display: none;
    }
  }
}

$gutter-width: 15px;
$outer-column-width: 27px;
$header-padding-top: 15px;
$header-padding-bottom: $header-padding-top;
$header-height: 20px * 2 + $header-padding-top + $header-padding-bottom;
$header-height-mobile: 25px + $header-padding-top + $header-padding-bottom;

$mobile: 700px;
$tablet: 1000px;

@mixin sans-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-button {
    display: none;
  } /* Chrome */
}

@mixin unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@mixin common-link {
  color: inherit;
  text-decoration: none;
  transition: color 300ms;

  :hover {
    transition: color 150ms;
  }

  // .dark &:hover {
  //   color: $bg-dark-accent;
  // }

  // .light &:hover {
  //   color: $bg-light-accent;
  // }
}
