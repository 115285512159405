@use "../constants" as *;

.AlbumPreview {
  @include sans-scrollbar;
  padding-top: 0;

  gap: $gutter-width;
  display: grid;
  overflow: scroll;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  max-width: 1200px;
  justify-self: center;

  &__link {
    width: 100%;
  }

  &__trailer {
    height: 100%;
    padding-left: 200px;
  }

  &__image {
    height: 100%;
  }

  &__indicator {
    height: 3px;
    width: 100%;
    margin-top: 3px;

    display: block;

    .dark & {
      background: $fg-dark-main;
    }

    .light & {
      background: $fg-light-main;
    }
  }
}
