@use "constants" as *;

.About {
  width: 100%;
  min-height: 80vh;
  padding-top: $header-height;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  &__container {
    height: min-content;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: $gutter-width;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $gutter-width;
  }

  &__image {
    width: 100%;
    max-width: calc(100vh - 2 * $gutter-width - $header-height);
    aspect-ratio: 1/1;
    margin-left: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background 300ms;

    & span {
      font-size: 13px;
      font-weight: 600;

      transition: color 300ms;
    }
  }

  &__text {
    width: 100%;
    max-width: 600px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $gutter-width;
  }
}

@media (max-width: $mobile) {
  .About {
    padding-top: $header-height-mobile;
  }
}

@media (max-width: 768px) {
  .About {
    height: unset;

    &__container {
      grid-template-columns: 1fr;
    }

    &__image {
      height: unset;
      width: 100%;
    }

    &__text {
      padding-top: 0;
      width: 100%;
    }
  }
}

.light .About {
  &__image {
    background: #fff;
  }
}

.dark .About {
  &__image {
    background: #000;
  }
}

.Bio {
  display: flex;
  text-align: justify;
  flex-direction: column;
  gap: 14px;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;

  &--link {
    @include common-link;
    text-decoration: underline;
  }

  &__name {
    transition: color 300ms;
  }

  &__text {
    display: inline;
    transition: color 300ms;
  }
}

.Socials {
  width: min-content;

  display: flex;
  flex-direction: column;
  gap: 30px;

  &__topline {
    height: 3px;
    width: 100%;

    transition: background 300ms;
  }

  &__links {
    width: min-content;

    display: flex;
    gap: 16px;
  }

  &--link {
    @include common-link;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.dark .Socials {
  &__topline {
    background: $fg-dark-main;
  }
}

.light .Socials {
  &__topline {
    background: $fg-light-main;
  }
}
