@use "../constants" as *;

.ScrollTop {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $gutter-width;
  padding-top: calc($gutter-width * 2);
  padding-bottom: calc($gutter-width * 3);

  &__button {
    @include plain-button;
    padding: 20px;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    font-size: 16px;
    font-weight: bold;
    font-variant: small-caps;

    .light & {
      color: $fg-light-main;
    }

    .dark & {
      color: $fg-dark-main;
    }
  }
}
