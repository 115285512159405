@use "constants" as *;

.Header {
  height: $header-height;
  width: 100%;
  padding: $header-padding-top $gutter-width;
  padding-bottom: $header-padding-bottom;

  position: fixed;
  top: 0;
  z-index: 1000;

  display: grid;
  grid-gap: $gutter-width;
  grid-template-columns: repeat(6, 1fr);

  @include unselectable;
  font-size: 16px;
  font-weight: bold;
  font-variant: small-caps;

  &__background {
    height: $header-height;
    width: 100%;

    position: fixed;
    top: 0;
    z-index: 10;

    transition: background 300ms;
    background: rgba($bg-light-main, 0.98);
  }

  &--btn,
  &--link {
    @include strikethrough-button;
    transition: color 300ms;
    font-size: inherit;
    font-weight: inherit;
    font-variant: inherit;
    color: inherit;
  }

  &__name {
    justify-self: left;
    opacity: 1;
    transition: opacity 300ms;
  }

  &__bio {
    grid-column: 2 / span 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__nav {
    grid-column: 5 / span 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-gap: 0 $gutter-width;
    justify-self: end;
    justify-items: end;
    align-items: start;
  }

  &__mode {
    justify-self: right;
  }

  &__menu {
    display: none;
  }
}

.light .Header {
  color: $fg-light-main;

  &__background {
    background: rgba($bg-light-main, 0.98);
  }

  ::after {
    background: $fg-light-main;
  }

  &--open {
    color: $fg-dark-main;

    ::after {
      background: $fg-dark-main;
    }
  }
}

.dark .Header {
  color: $fg-dark-main;

  &__background {
    background: rgba($bg-dark-main, 0.98);
  }

  ::after {
    background: $fg-dark-main;
  }

  &--open {
    color: $fg-dark-main;

    ::after {
      background: $fg-dark-main;
    }
  }
}

@media (max-width: $mobile) {
  .Header {
    height: $header-height-mobile;
    grid-template-columns: 1fr 1fr;

    font-size: 18px;

    &__mode,
    &__nav,
    &__bio {
      display: none;
    }

    &__name {
      &.hidden {
        transition-delay: 0ms;
        opacity: 0;
      }

      ::after {
        display: none;
      }
    }

    &__menu {
      display: flex;
      justify-self: right;

      // hover styles do not disappear on mobile
      &:not(.selected)::after {
        width: 0px;
      }
    }

    &__background {
      height: $header-height-mobile;
      transition: opacity 300ms;
    }
  }
}
